<template>
  <deck-select
    ref="select"
    :model-value="modelValue"
    :label="label || $t('global.sheet')"
    :items="items"
    :hint="hint"
    :rules="rules"
    required
    :disabled="disabled"
    @update:model-value="$emit('update:modelValue', $event)"
  />
</template>
<script>
import { mapActions, mapState, mapGetters } from '~/assets/javascript/modules/vuex';
import DeckSelect from '~/deck/select';

export default {
  name: 'SheetSelect',
  components: {
    DeckSelect,
  },
  props: {
    /**
     * The modelValue of the select.
     * @type {string}
     * @default null
     */
    modelValue: {
      type: String,
      default: null,
    },

    /**
     * Whether the select is disabled.
     * @type {boolean}
     * @default false
     */
    disabled: {
      type: Boolean,
      default: false,
    },

    /**
     * The hint to display.
     * @type {string}
     * @default null
     */
    hint: {
      type: String,
      default: null,
    },

    /**
     * Whether to allow integration sheets.
     * @type {boolean}
     * @default false
     */
    allowIntegration: {
      type: Boolean,
      default: false,
    },

    /**
     * Whether to show only public sheets.
     * @type {boolean}
     * @default false
     */
    publicOnly: {
      type: Boolean,
      default: false,
    },

    /**
     * The rules to validate against.
     * @type {Array}
     * @default []
     */
    rules: {
      type: Array,
      default: () => [],
    },

    /**
     * The label to display.
     * @type {string}
     * @default $t('global.sheet')
     */
    label: {
      type: String,
      default: null,
    },
  },
  emits: ['update:modelValue'],
  setup() {
    return {
      ...mapState('viewOptions', ['sheetsCache']),
      ...mapGetters('workspace', ['sheetsOrder']),
      ...mapActions('viewOptions', ['fetchSheetsCache']),
    };
  },
  computed: {
    allowedSheets() {
      return this.sheetsCache.filter(sheet => (
        (this.allowIntegration || !sheet.integration)
        && (!this.publicOnly || sheet.sheet_public)
      ));
    },
    items() {
      return this.allowedSheets
        .map(sheet => ({
          text: this.calculateSheetWithGroupName(sheet),
          value: sheet.id,
        }));
    },
  },
  async mounted() {
    try {
      await this.fetchSheetsCache();
    } catch (error) {
      this.$errorRescue(this, error, 'fetchSheetsCache on SheetSelect');
    }
  },
  methods: {
    calculateSheetWithGroupName(sheet) {
      const result = [];

      let complete = false;

      const buildResult = (items) => {
        items.forEach((element) => {
          if (complete) return;

          if (!element.items && element.id === sheet.id) {
            complete = true;
          } else if (element.items) {
            result.push(element.group_name);
            buildResult(element.items);

            if (!complete) result.pop();
          }
        });
      };

      buildResult(this.sheetsOrder);

      return [...result, sheet.name].join(' / ');
    },
  },
};
</script>
